import axios, { AxiosResponse } from 'axios';
import { AccessControls } from '../../Typings/adminTypes';
import {
  UserRequest, MobileActivation, User, UserRequestChannel,
} from '../../Typings/userTypes';
import { ToastType } from '../../Typings/toastTypes';
import { Channel } from '../../Typings/channelTypes';
import type SocketController from '../../Utils/SocketController';
import userLogic, { UserChannelRes } from './usersLogic';
import { ADMIN, SENDER, SENDER_ACCESS_CONTROL } from '../../Utils/constants';

const validateData = (
  username: string, email: string, password: string, setErrorState: (msg: string) => void, roleState: { name: string }, edit: boolean,
): boolean => {
  const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!username.length) {
    setErrorState('Please enter a username');
    return false;
  }
  if (username.includes('-')) {
    setErrorState('\'-\' character not permitted in username');
    return false;
  }
  if (email.length) {
    if (!regEx.test(email)) {
      setErrorState('Please enter a valid email');
      return false;
    }
  }
  if (!edit || password.length) {
    if (password.length < 8) {
      setErrorState('Please enter a password of at least 8 characters.');
      return false;
    }
  }

  if (roleState.name === 'Select A Role' || !roleState || !roleState.name) {
    setErrorState('Please select a role');
    return false;
  }
  return true;
};

const filterAccessControls = (accessControls: AccessControls[], roleName: string): string[] => {
  if (roleName === ADMIN) { // "Admin" role always gets all access controls
    return accessControls.map((a) => a._id);
  }
  if (roleName === SENDER) { // "Sender" always only gets the "geo_send_alert" access control
    return accessControls.filter((a) => a.name === SENDER_ACCESS_CONTROL).map((a) => a._id);
  } // "Mobile" never gets any access controls
  return [];
};

const submitAddUser = async ({
  e,
  setErrorState,
  username,
  email,
  password,
  roleState,
  channelState,
  channels,
  accessControls,
  mobileActivation,
  dispatch,
  toggle,
  socket,
}: {
  e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  setErrorState: (msg: string) => void,
  username: string,
  email: string,
  password: string,
  roleState: { id: string, name: string },
  channelState: UserRequestChannel[],
  channels: Channel[],
  accessControls: AccessControls[],
  mobileActivation: MobileActivation,
  dispatch: (action: { type: string, payload: User[] | ToastType | User }) => void,
  toggle: () => void,
  socket: SocketController,
}): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();
  setErrorState('');
  const isValid = validateData(username, email, password, setErrorState, roleState, false);
  if (!isValid) {
    return false;
  }

  const addUserData = {
    username,
    email,
    password,
    admin: roleState.name === ADMIN || roleState.name === SENDER,
    role: roleState.id,
    channels: channelState,
    accessControls: filterAccessControls(accessControls, roleState.name),
    mobileActivation,
  };
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    const res: AxiosResponse<{
      channels: UserChannelRes[],
      _id: string,
      sticky: boolean
    }> = await axios.post('/api/v2/user/users', addUserData, config);
    const errMessage = res.status === 401 ? 'Unauthorized' : `Error adding ${username} user`;
    if (res.status === 201) {
      // dispatch calls occur in the socket controller
      // API returns channel fields as a bare list of channel IDs, so we re-map
      const fullChannels = res.data.channels.map(
        (dataChannels) => ({ ...dataChannels, channel: channels.find((c) => c._id === dataChannels.channel) }),
      );
      const newUser = { ...res.data, channels: fullChannels };
      socket.emitNewUser(newUser);
      toggle();
      return true;
    }
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Users',
        message: `${errMessage}`,
        autoDismiss: true,
      },
    });
    return false;
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Users',
        message: `Error adding ${username} user`,
        autoDismiss: true,
      },
    });
    return false;
  }
};

const submitEditUser = async ({
  e,
  id,
  channelState,
  channels,
  roleState,
  username,
  password,
  email,
  mobileActivation,
  accessControls,
  toggle,
  dispatch,
  setErrorState,
  socket,
}: {
  e: React.MouseEvent<HTMLButtonElement> | undefined,
  id: string,
  channelState: UserRequestChannel[],
  channels: Channel[],
  accessControls: AccessControls[],
  roleState: { id: string, name: string },
  username: string,
  password: string,
  email: string,
  mobileActivation: MobileActivation,
  toggle: () => void,
  dispatch: (action: { type: string, payload: ToastType | User[] }) => void,
  setErrorState: (msg: string) => void,
  socket: SocketController,
}): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();

  const isValid = validateData(username, email, password, setErrorState, roleState, true);
  if (!isValid) {
    return false;
  }

  const data: UserRequest = {
    admin: roleState.name === ADMIN || roleState.name === SENDER,
    username,
    role: roleState.id,
    accessControls: filterAccessControls(accessControls, roleState.name),
    mobileActivation,
  };
  if (channelState) data.channels = channelState;
  if (password.length) data.password = password;
  if (email.length) data.email = email;

  // eslint-disable-next-line one-var
  let res: AxiosResponse<{ channels: UserChannelRes[], _id: string, sticky: boolean }>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    res = await axios.put(`/api/v2/user/users/${id}`, data, config);
  } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Users',
        message: `Error editing ${data.username} user`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error editing ${data.username} user`;
  if (res.status === 200) {
    if (data.username !== 'GUEST') {
      // For all users other than GUEST, the API returns channel fields as a bare list of channel IDs, so we re-map
      const fullChannels = res.data.channels.map(
        (dataChannels) => ({ ...dataChannels, channel: channels.find((c) => c._id === dataChannels.channel) }),
      );
      const updatedUser = { ...res.data, channels: fullChannels };
      socket.emitUpdatedUser(updatedUser);
    } else {
      const guestData = await userLogic.getUserById(id);
      if (typeof (guestData) !== 'boolean') {
        socket.emitUpdatedGuest(guestData.user);
      }
    }
    toggle();
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Users',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const addEditUserLogic = {
  validateData,
  submitAddUser,
  submitEditUser,
};

export default addEditUserLogic;
