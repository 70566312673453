import axios, { AxiosResponse } from 'axios';
import { Point } from 'leaflet';
import Color from 'color';
import { Channel } from '../../Typings/channelTypes';
import { ToastType } from '../../Typings/toastTypes';
import type SocketController from '../../Utils/SocketController';
import { getRoles } from '../Admin/adminLogic';
import usersLogic from '../User/usersLogic';
import { Role } from '../../Typings/adminTypes';
import { User } from '../../Typings/userTypes';

const channelsURL = '/api/v2/core/channels/';

const validateChannel = (
  setErrorMessage: (message: string) => void,
  channel: {
    name: string,
    style: string,
    color: string,
    sticky: boolean,
    type?: 'geofence' | 'other',
  },
): boolean => {
  if (typeof channel.name !== 'string' || channel.name === '' || channel.name === null) {
    setErrorMessage('Please enter a valid channel name');
    return false;
  }
  if (/^[a-zA-Z0-9_\s]*$/.test(channel.name) === false) {
    setErrorMessage('Please enter a channel name without symbols');
    return false;
  }
  if (/^#[0-9a-f]{3,6}$/i.test(channel.style) === false) {
    setErrorMessage('Please enter a valid style');
    return false;
  }
  if (/^#[0-9a-f]{3,6}$/i.test(channel.color) === false) {
    setErrorMessage('Please enter a valid color');
    return false;
  }
  return true;
};

const getChannels = async (
  dispatch: (action: { type: string; payload?: Channel[]; payload2?: Channel[] }) => void,
): Promise<boolean> => {
  let response: AxiosResponse<Channel[]>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { response = await axios.get(channelsURL, config); } catch (err) {
    return false;
  }
  if (response.status === 200) {
    dispatch({ type: 'GET_CHANNELS', payload2: response.data });
    dispatch({ type: 'VISIT_CHANNELS' });
    return true;
  }
  return false;
};

const getChannelById = async ({
  id,
  setChannelName,
  setChannelStyle,
  setChannelColor,
  setChannelSticky,
  setChannelChat,
  setChannelType,
  setChannelCircle,
  setChannelCoordinates,
  setErrorMessage,
}: {
  id: string,
  setChannelName: (name: string) => void,
  setChannelStyle: (style: string) => void,
  setChannelColor: (color: string) => void,
  setChannelSticky: (sticky: boolean) => void,
  setChannelChat: (chat: boolean) => void,
  setChannelType: (type: 'geofence' | 'other') => void,
  setChannelCircle: (circle: { center: Point | number[] | null, radius: number | undefined }) => void,
  setChannelCoordinates: (coordinates: { lat: number, lon: number } | undefined) => void,
  setErrorMessage: (message: string) => void,
}): Promise<boolean> => {
  let response: AxiosResponse<Channel>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { response = await axios.get(`${channelsURL}${id}`, config); } catch (err) {
    return false;
  }
  if (response.status === 200) {
    let newStyle, newColor;
    try {
      const style = new Color(response.data.style);
      newStyle = style.hex();
    } catch (err) {
      newStyle = '#000000';
    }
    try {
      const color = new Color(response.data.color);
      newColor = color.hex();
    } catch (err) {
      newColor = '#FFFFFF';
    }
    setChannelName(response.data.name);
    setChannelStyle(newStyle);
    setChannelColor(newColor);
    setChannelSticky(response.data.sticky);
    if (response.data.chat) setChannelChat(response.data.chat);
    if (response.data.type) setChannelType(response.data.type);
    if (response.data.type === 'geofence' && response.data.coordinates && response.data.circle) {
      setChannelCircle({ center: null, radius: response.data.circle.radius });
      setChannelCoordinates(response.data.coordinates);
    }
    return true;
  }
  setErrorMessage(`Error fetching ${response.data.name} channel`);
  return false;
};

const editChannel = async (
  dispatch: (action: { type: string; payload: Channel | ToastType }) => void,
  _id: string,
  channel: { name: string, style: string, color: string, sticky: boolean, type: 'geofence' | 'other';
    coordinates?: { lat: number, lon: number }, circle?: { center: Point | number | null, radius: number }
  },
  socket: SocketController,
): Promise<boolean> => {
  let response: AxiosResponse<Channel>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { response = await axios.put(`${channelsURL}${_id}`, channel, config); } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Channels',
        message: `Error editing ${channel.name} channel`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = response.status === 401 ? 'Unauthorized' : `Error editing ${channel.name} channel`;
  if (response.status === 200) {
    socket.emitUpdateChannel(response.data);
    return false;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Channels',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const addChannel = async (
  dispatch: (action: { type: string, payload?: Channel | ToastType | Role[] | User[] }) => void,
  channel: { name: string, style: string, color: string, sticky: boolean, type: 'geofence' | 'other';
    coordinates?: { lat: number, lon: number }, circle?: { center: Point | number | null, radius: number }
  },
  socket: SocketController,
): Promise<boolean> => {
  let response: AxiosResponse<{ newChannel: Channel }>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { response = await axios.post(`${channelsURL}`, channel, config); } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Channels',
        message: `Error adding ${channel.name} channel`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = response.status === 401 ? 'Unauthorized' : `Error adding ${channel.name} channel`;
  if (response.status === 201) {
    socket.emitNewChannel(response.data.newChannel);
    await getRoles(dispatch);
    await usersLogic.getUsers(dispatch);
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Channels',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const deleteChannel = async (
  { id, delName } : { id: string, delName: string },
  dispatch: (action: { type: string, payload?: Channel[] | ToastType, payload3?: string }) => void,
  socket: SocketController,
): Promise<boolean> => {
  let res: AxiosResponse<{ channel_deleted: Channel }>; // eslint-disable-line
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { res = await axios.delete(`${channelsURL}${id}`, config); } catch (err) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Channels',
        message: `Error deleting ${delName} channel`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error deleting ${delName} channel`;
  if (res.status === 200) {
    const data = res.data.channel_deleted;
    const channelName = data.name;
    const channelId = data._id;
    socket.emitDeleteChannel(channelId, channelName, data);
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Channels',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};

const handleSubmit = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  channel: { name: string, style: string, color: string, sticky: boolean, type: 'geofence' | 'other';
    coordinates?: { lat: number, lon: number }, circle?: { center: Point | number | null, radius: number } },
  setErrorMessage: (message: string) => void,
  dispatch: (action: { type: string; payload?: Channel | ToastType | Role[] | User[] }) => void,
  socket: SocketController,
  add: boolean,
  toggle: () => void,
  _id?: string,
): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();
  const trimmedChannel = { ...channel, name: channel.name.trim() };
  if (validateChannel(setErrorMessage, trimmedChannel) === true) {
    if (!add && _id) {
      await editChannel(dispatch, _id, trimmedChannel, socket);
      toggle();
      return true;
    } await addChannel(dispatch, trimmedChannel, socket);
    toggle();
  }
  return true;
};

const channelLogic = {
  getChannels,
  getChannelById,
  editChannel,
  addChannel,
  deleteChannel,
  handleSubmit,
  validateChannel,
};

export default channelLogic;
