import { Copyright, CopyrightState } from '../../Typings/copyrightTypes';

const valertTier = process.env.REACT_APP_VALERT_TIER;

const initialState: CopyrightState = {
  copyrights: {
    version: '4.1.2',
    model: 'V-ALERT',
    tier: valertTier === 'pro' ? 'Pro' : 'Standard',
    copyright: '2023',
  },
};

const copyrightReducer = (
  state: CopyrightState = initialState,
  action:{ type:string; payload:Copyright },
): CopyrightState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default copyrightReducer;
