import axios, { AxiosResponse } from 'axios';
import jwt from 'jwt-simple';

export const deauth = (dispatch: ({ type, payload }: { type: string, payload: boolean })=> void): boolean => {
  sessionStorage.removeItem('authToken');
  sessionStorage.removeItem('channels');
  sessionStorage.removeItem('permissions');
  sessionStorage.removeItem('userRole');
  sessionStorage.removeItem('userRoleName');
  sessionStorage.removeItem('authorizedUser');
  dispatch({ type: 'LOGOUT', payload: false });
  dispatch({ type: 'LOGIN', payload: false });
  return true;
};

export const refreshToken = async (): Promise<boolean> => {
  try {
    const res: AxiosResponse<{ token: string }> = await axios.get('/api/v2/core/auth/refresh', {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` },
    });
    sessionStorage.setItem('authToken', res.data.token);
    const secret = process.env.REACT_APP_JWT_SECRET;
    const decoded = secret ? jwt.decode(res.data.token, secret) as { channels: string[], permissions: string[], userId: string, role: string } : null;
    if (decoded !== null) {
      sessionStorage.setItem('channels', JSON.stringify(decoded.channels));
      sessionStorage.setItem('permissions', JSON.stringify(decoded.permissions));
    } else {
      sessionStorage.setItem('channels', '');
      sessionStorage.setItem('permissions', '');
    }
  } catch (error) {
    return false;
  }
  return true;
};
