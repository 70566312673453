import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../Redux/reducers';
import { getAccessControls, getRoles } from '../Admin/adminLogic';
import channelLogic from '../Channels/channelLogic';
import userLogic from './usersLogic';
import DeleteModal from '../../Components/DeleteModal';
import AddEditModal from '../../Components/AddEditModal';
import AddEditUser from './AddEditUser';
import { User, UserChannel } from '../../Typings/userTypes';
import { AccessControls, Role } from '../../Typings/adminTypes';
import Card from '../../Components/Card';
import { Channel } from '../../Typings/channelTypes';
import { ToastType } from '../../Typings/toastTypes';
import type SocketController from '../../Utils/SocketController';
import DeviceDetailModal from './DeviceDetail';
import Button from '../../Components/Button';
import { ADMIN } from '../../Utils/constants';

export const renderUserSection = ({
  users,
  accessControls,
  roles,
  channels,
  setEditId,
  setShowEditModal,
  setDelId,
  setDelName,
  setShowDeleteModal,
  setUserTemplateName,
  setShowDeviceDetailModal,
}:{
  users: User[],
  accessControls: AccessControls[],
  roles: Role[],
  channels: Channel[],
  setEditId: (id: string) => void,
  setShowEditModal: (x: boolean) => void,
  setDelId: (id: string) => void,
  setDelName: (id: string) => void,
  setShowDeleteModal: (x: boolean) => void,
  setUserTemplateName: (name: string) => void,
  setShowDeviceDetailModal: (x: boolean) => void,
}): JSX.Element[] => {
  const isAdmin = sessionStorage.getItem('userRoleName') === ADMIN;

  const allUsers = users.sort((a, b) => a.username.localeCompare(b.username)).map((user) => {
    const usersContent = [
      {
        heading: user.username,
        data: [user.email, userLogic.mapRoles(user.role as string, roles)],
      },
      {
        heading: 'channels',
        data: user.channels.map((u: UserChannel) => {
          const matchingChannel = channels.find((c) => c._id === u.channel._id);
          if (matchingChannel) return matchingChannel.name;
          return u.channel._id;
        }),
      },
      {
        heading: 'devices',
        data: [],
        button: <Button
          text="View and Edit"
          onClick={() => {
            setUserTemplateName(user.username);
            setShowDeviceDetailModal(true);
          }}
        />,
      },
    ];
    return (
      <Card
        key={user._id}
        name={user.username}
        id={user._id || ''}
        content={usersContent}
        handleDelete={(id, name) => { setDelId(id); setDelName(name); setShowDeleteModal(true); }}
        handleEdit={(id) => { setEditId(id); setShowEditModal(true); }}
        hideDelete={!isAdmin || user.username === 'GUEST' || user.username === 'admin'}
        hideEdit={!isAdmin}
        ariaLabel={`${user.username} user on User Page.`}
      />
    );
  });
  return allUsers;
};

export const handleUserDelete = async (
  { delId, delName } : { delId: string, delName: string },
  dispatch: (action: { type: string, payload: string | ToastType }) => void,
  socket: SocketController,
):Promise<boolean> => {
  if (!delId.length) return false;
  const res = await userLogic.deleteUser({ delId, delName }, dispatch, socket);
  return res;
};

const Users = (): JSX.Element => {
  const dispatch = useDispatch();
  const [editId, setEditId] = useState('');
  const [delId, setDelId] = useState('');
  const [delName, setDelName] = useState('');
  const [userTemplateName, setUserTemplateName] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeviceDetailModal, setShowDeviceDetailModal] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const { socket } = useSelector((state: RootState) => state.socket);
  const { users, usersVisited } = useSelector((state: RootState) => state.users);
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const { accessControls, roles, rolesVisited } = useSelector((state: RootState) => state.admin);
  const isAdmin = sessionStorage.getItem('userRoleName') === ADMIN;

  const toggleShowAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleDeviceDetailModal = () => {
    setShowDeviceDetailModal(!showDeviceDetailModal);
  };

  useEffect(() => {
    if (!usersVisited || !users.length) userLogic.getUsers(dispatch);
    if (!channelsVisited || !channels.length) channelLogic.getChannels(dispatch);
    if (!accessControls.length) getAccessControls(dispatch); // eslint-disable-line
    if (!rolesVisited || !roles.length) getRoles(dispatch);
  }, []);

  return (
    <main className="outerContainer">
      <section>
        <header
          aria-label="Users Page"
          tabIndex={0} // eslint-disable-line
          className="addSectionHeading sectionHeading"
        >
          <h1>Users</h1>
          <div className="addBtnContainer" style={{ visibility: isAdmin ? 'visible' : 'hidden' }}>
            <FontAwesomeIcon
              aria-label="Add Users Button"
              tabIndex={0} // eslint-disable-line
              role="button"
              onKeyPress={() => setShowAddModal(true)}
              icon={faPlusCircle}
              color="#6DABFF"
              size="2x"
              className="addRoleBtn mr-3"
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </header>
      </section>
      <div className="card-container">
        {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          toggleModal={toggleDeleteModal}
          deleteTitle="User"
          deleteFunction={() => handleUserDelete({ delId, delName }, dispatch, socket)}
        />
        )}
        { showAddModal && (
        <AddEditModal
          open={showAddModal}
          modalHeading="Add User"
          size={expandModal ? 'xl' : undefined}
          modalBody={<AddEditUser toggle={toggleShowAddModal} modalExpansion={expandModal} setModalExpansion={setExpandModal} />}
          toggleModal={toggleShowAddModal}
        />
        )}
        { showEditModal && (
        <AddEditModal
          open={showEditModal}
          modalHeading="Edit User"
          size={expandModal ? 'xl' : undefined}
          modalBody={<AddEditUser toggle={toggleEditModal} id={editId} modalExpansion={expandModal} setModalExpansion={setExpandModal} />}
          toggleModal={toggleEditModal}
        />
        )}
        { userTemplateName && showDeviceDetailModal && (
        <AddEditModal
          open={showDeviceDetailModal}
          modalHeading={`Device Detail - ${userTemplateName}`}
          modalBody={<DeviceDetailModal template={userTemplateName} />}
          toggleModal={toggleDeviceDetailModal}
          size="lg"
        />
        )}
        <section>
          { users.length ? renderUserSection({
            users,
            accessControls,
            roles,
            channels,
            setEditId,
            setShowEditModal,
            setDelId,
            setDelName,
            setShowDeleteModal,
            setUserTemplateName,
            setShowDeviceDetailModal,
          }) : null}
        </section>
      </div>
    </main>
  );
};

export default Users;
